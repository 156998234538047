<template>
  <div class="shownav" @touchmove.prevent>
    <div class="shownav_head">
      <div class="logo">
        <img src="../img/mobilelogo.png">
      </div>
      <div class="closebtn" @click="closenav">
        <img src="../img/nav_close.png">
      </div>
    </div>
    <div class="shownav_body">
      <ul class="nav_list" @click="closenav">
        <li class="nav_item" @click="gotoabout"><span>About Us</span><img src="../img/nav_right.png" /></li>
        <li class="nav_item" @click="gotoview('mservice')"><span>Term Of Service</span><img
            src="../img/nav_right.png" /> </li>
        <li class="nav_item" @click="gotoview('mprivacypolicy')"><span>Privacy Policy</span><img
            src="../img/nav_right.png" /> </li>
        <li class="nav_item" @click="gotocontact"><span>Contact Us</span><img src="../img/nav_right.png" /> </li>
        <li class="nav_item" @click="gotoview('mrefundpolicy')"><span>Refund Policy</span><img
            src="../img/nav_right.png" /> </li>
        <!-- <li class="nav_item" @click="gotoview('mcancellationpolicy')"><span>Cancellation Policy</span><img
            src="../img/nav_right.png" /> </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {

  methods: {
    ...mapMutations(['changenavshow']),
    closenav() {
      this.changenavshow(false)
      // document.documentElement.scrollTop = 0;
      // this.$refs.shownav.style.display = 'none'
    },
    gotoview(path) {
      console.log('ddd');
      this.$router.push(`${path}`)
      document.documentElement.scrollTop = 0
    },
    gotoabout() {
      console.log('navlist about');
      this.$router.push({
        path: '/mobile',
        query: {
          mobileabout: 1
        }
      })
      return
    },
    gotocontact() {
      this.$router.push({
        path: '/mobile',
        query: {
          mobilecontact: 1
        }
      })
      return
    }
  }
}
</script>

<style lang="scss" scoped src="./navlist.scss">
</style>